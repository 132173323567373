<template>
  <div class="gallery">
    <div class="gallery-container">
      <div v-for="item in showedGalleryItems" :key="item" @click="openModal(item)" class="gallery-item">
        <img v-if="item.type == 'img'"
             :src="$store.state.serverRoot+'images/gallery/' + item.src.split('.')[0] + '500x410.'+item.src.split('.').pop()" alt="">

        <video v-else autoplay="autoplay" loop controls muted preload="auto">
          <source :src="$store.state.serverRoot+'images/gallery/' + item.src" type="video/mp4">
        </video>
      </div>

    </div>
    <div ref="showMoreProductsBtn" class="products-show-more">
      <button @click="showMoreProducts">Показать еще</button>
    </div>

    <transition name="modal-fade">
      <GalleryModal v-if="modalState" @onCloseModal="closeModal" :item="modalItem" :contentLocation="'gallery'">
      </GalleryModal>
    </transition>

    <transition name="modal-fade">
      <LoadingModal v-if="loadingState">
      </LoadingModal>
    </transition>

  </div>
</template>

<script>
// @ is an alias to /src
import GalleryModal from '@/components/modals/GalleryModal.vue';
import LoadingModal from '@/components/modals/loadingModal.vue';
import { mapState } from 'vuex';

export default {
  name: 'GalleryView',
  components: {
    GalleryModal,
    LoadingModal,
  },
  data() {
    return {
      /* galleryItems: this.$store.state.galleryItems, */
      modalState: false,
      showedGalleryItems: [],
      showedGalleryItemsCount: 50,
      showedGalleryItemsIncrement: 50,
      modalItem: {},
      scrollPosition: 0,
      loadingState: true,
    };
  },
  computed: {
    ...mapState({
      galleryItems: state => state.gallery.all,
      showedGalleryCountState: state => state.showedGalleryCount,
      // ...
    }),
  },
  watch: {
    galleryItems(newValue) {
      this.showedGalleryItems = newValue.slice(0, this.showedGalleryItemsCount);
    },
    showedGalleryCountState(newValue) {
      this.showedGalleryItemsCount = newValue;
    },
  },
  methods: {
    scrollListener() {
      this.scrollPosition = window.scrollY;
    },
    openModal(item) {
      this.modalItem = item;
      this.modalState = true;
    },
    closeModal() {
      this.modalState = false;
    },

    showMoreProducts() {

      if (this.showedGalleryItemsCount + this.showedGalleryItemsIncrement
        <= this.galleryItems.length) {
        this.showedGalleryItemsCount += this.showedGalleryItemsIncrement;
        /*  this.$store.state.showedProductsCount = this.showedProductsCount; */
      } else {
        this.showedGalleryItemsCount = this.galleryItems.length;
        /* this.$store.state.showedProductsCount = this.showedProductsCount; */
        if (this.showedGalleryItemsCount >= this.galleryItems.length) {
          setTimeout(() => {
            this.$refs.showMoreProductsBtn.style.display = 'none';
          }, 500);
        }
      }

      this.showedGalleryItems = this.galleryItems.slice(0, this.showedGalleryItemsCount);
      this.$store.commit('setShowedGalleryCount', this.showedGalleryItemsCount);
    },
  },

  created() {

    this.showedGalleryItemsCount = this.showedGalleryCountState;
    this.$store.commit('setActivePage', 'gallery');
    if (this.$store.state.gallery?.all.length == 0) {
      this.$store.dispatch('gallery/getAll').then(() => {
        this.galleryItems = this.$store.state.gallery.all;
        this.showedGalleryItems = this.galleryItems.slice(0, this.showedGalleryItemsCount);
        this.loadingState = false;
      });
    } else {
      this.galleryItems = this.$store.state.gallery.all;
      this.showedGalleryItems = this.galleryItems.slice(0, this.showedGalleryItemsCount);
      this.loadingState = false;
    }
  },
  mounted() {

    window.scroll({
      left: 0, // до какого количества пикселей прокрутить вправо
      top: this.$store.state.galleryScroll, // до какого количество пикселей прокрутить вниз
      behavior: 'smooth', // определяет плавность прокрутки: 'auto' - мгновенно (по умолчанию), 'smooth' - плавно
    });
    window.addEventListener('scroll', this.scrollListener);
  },
  beforeUnmount() {
    this.$store.commit('setGalleryScroll', this.scrollPosition);
  },
  unmounted() {
    window.removeEventListener('scroll', this.scrollListener);
  },
};
</script>

<style scoped>
.preload-image {
  background-color: aliceblue;
  filter: blur(3px);
}

.gallery {
  padding: 100px 30px 80px 30px;
  min-height: 100vh;
  /* background-image: url("../assets/images/mainPage/11.jpg");*/
  background-color: rgba(189, 229, 255, 0.108);
  background: linear-gradient(to bottom, white, rgb(255, 239, 228), white);
  background-size: cover;
  position: relative;
}

.gallery-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 270px);
  grid-auto-rows: 370px;
  justify-items: stretch;
  gap: 20px 20px;
}

.gallery-item {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  background-color: rgb(240, 240, 240);
  box-shadow: 3px 3px 10px rgba(16, 1, 69, 0.153);
}

.gallery-item:hover {
  box-shadow: 5px 5px 15px rgba(55, 55, 55, 0.937);
  background-color: rgba(240, 240, 240, 0);
}

.gallery-item img,
.gallery-item video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
}

.gallery-item video {
  outline: unset;
  object-fit: cover;

}


@media (max-width: 908px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, 200px);
    grid-auto-rows: 280px;
  }
}


@media (max-width: 700px) {
  .gallery-container {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
  }
}


@media (max-width: 550px) {
  .gallery-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 10px;
  }
}

@media (max-width: 440px) {
  .gallery {
    padding-left: 10px;
    padding-right: 10px;
  }

  .gallery-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 10px;
  }
}

@media (max-width: 320px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

</style>
