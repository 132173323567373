import { createRouter, createWebHashHistory } from 'vue-router'


import HomeView from '../views/HomeView.vue'
import ProductsView from '../views/ProductsView.vue'
import ProductCardView from '../views/ProductCardView.vue'
import CartView from '../views/CartView.vue'
import GalleryView from '../views/GalleryView.vue'
import userCardView from '../views/userCardView.vue'
import signInView from '../views/signInView.vue'
import signUpView from '../views/signUpView.vue'

import adminMainView from '../views/admin/mainView.vue'
import adminProducts from '../views/admin/products.vue'
import adminProductCard from '../views/admin/productCard.vue'
import adminGallery from '../views/admin/gallery.vue'
import adminFeedback from '../views/admin/feedback.vue'
import adminDiscounts from '../views/admin/discounts.vue'
import adminOrders from '../views/admin/orders.vue'
import adminActivities from '../views/admin/activities.vue'

const routes = [{
        path: '/home',
        name: 'home',
        component: HomeView
    },
    {
        path: '/products',
        name: 'products',
        component: ProductsView
    },
    {
        path: '/products/:id',
        name: 'product-card',
        component: ProductCardView
    },
    {
        path: '/Cart',
        name: 'Cart',
        component: CartView
    },
    {
        path: '/',
        name: 'gallery',
        component: GalleryView
    },
    {
        path: '/user',
        name: 'user-card',
        component: userCardView
    },
    {
        path: '/sign-in',
        name: 'signIn',
        component: signInView
    },
    {
        path: '/sign-up',
        name: 'signUp',
        component: signUpView
    },
    {
        path: '/admin/',
        name: 'admin',
        component: adminMainView
    },
    {
        path: '/admin/products',
        name: 'adminProducts',
        component: adminProducts
    },
    {
        path: '/admin/products/:id',
        name: 'admin-product-card',
        component: adminProductCard
    },
    {
        path: '/admin/gallery',
        name: 'adminGallery',
        component: adminGallery
    },
    {
        path: '/admin/feedback',
        name: 'adminFeedback',
        component: adminFeedback
    },
    {
        path: '/admin/discounts',
        name: 'adminDiscounts',
        component: adminDiscounts
    },
    {
        path: '/admin/orders',
        name: 'adminOrders',
        component: adminOrders
    },
    {
        path: '/admin/activities',
        name: 'adminActivities',
        component: adminActivities
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
    /* ,
        scrollBehavior() {

            // eslint-disable-next-line no-unused-vars
            return {
                top: 0,
                behavior: 'smooth'
            }

        }, */
    /* scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
    } */
})

export default router
